import React from "react"

import { Link } from "gatsby"
import GalleryTitle from "./title"
import GalleryEventsList from "./gallery-events";
import UseHttpRequest from "../../hooks/HttpRequestEvents"

const GalleryPage = () => {
  const allData = UseHttpRequest()
  if (allData.length <= 0) {
    return (
      <div className="row justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  }
  const data = allData.Events
  var imageCount = 0;
  var eventPhotos = [];
  for (var j = 0; j < data.length; j++) {
    for (var i = 0; i < data[j].EventPhotos.length; i++) {
      eventPhotos.push(

        <div className="col-md-3 px-1" >
          <a href={"/event-gallery/" + data[j].Slug}>
            <img src={data[j].EventPhotos[i].ImageURL} alt={data[j].EName} title={data[j].EName} width="300px" />
          </a>
        </div>
      )
      if (i >= 5) {
        break;
      }
    }

    if (j >= 29) {
      break
    }
  }
  return (
    <>
      <GalleryTitle />
      <section className="event-details-area py-5">
        <div className="container">

          <div className="col-12 col-lg-12 mb-3">
            <div className="row">
              {eventPhotos}
            </div>
          </div>
        </div>
      </section>
      <section className="event-section pt-70 pb-70">
        <div className="container">
          <div className="section-title">
            <h2>Gallery by Events</h2>
          </div>
          <GalleryEventsList />
        </div>
      </section>
    </>

  )
}

export default GalleryPage
