import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GalleryPage from "../components/gallery/index";
const blog = () => {
  return (
    <Layout>
      <SEO
        title="Events Gallery | Kentucky Tamil Sangam"
        desc="Events Gallery | Kentucky Tamil Sangam"
        pathname="/gallery"
        keywords="Kentucky Tamil Sangam - Events Gallery"
      />
      <GalleryPage />
    </Layout>
  );
};

export default blog;
