import React from "react"
import { Link } from "gatsby"
import UseHttpRequest from "../../hooks/HttpRequestKural"
const GalleryTitle = () => {
  const allData = UseHttpRequest()
  const data = allData.Slider
  return (
    <div className="page-title-area">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container">
            <div className="page-title-content">
              <h2>Gallery</h2>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Gallery</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GalleryTitle
